<template>
    <div class="bbb">
        <van-nav-bar
        left-arrow
        title="是否有统筹档案"
        @click-left="onClickLeft"
        />
        <div class="getdrug margin-auto" >
            <div class="Drugs_top DrugsInfo margin_top">
            <el-input placeholder="请输入手机号查询" @input="input_search" v-model="search_value" >
                <template slot="append">
                    <el-button icon="el-icon-search"  @click="loadList">查询</el-button>
                </template>
            </el-input>
            </div>
            <div class=" margin_top"  >
                <div  v-if="state == 1" >
                    <van-result type="success" title="存在档案" tip="您可以进行统筹报销"  />
                    <van-button type="primary" @click="tu('/')" block>去统筹</van-button>
                </div>
                <div v-else-if="state == 0"> 
                    <van-result
                    type="fail"
                    title="尚未查询到档案"
                    tip="您可以去进行建档"
                    
                    />
                    <van-button type="primary" @click="tu('/Patient')" block>去建档</van-button>
                </div>
               
                <div class="van-nodata" v-if="state == 3">
                <img class="van-nodata__figure" src="../assets/image/nocollection.png" alt="">
                <p class="van-nodata__title">查询是否有统筹档案</p>
                <p class="van-nodata__tip">输入手机号查询</p>
            </div>
            </div>
        </div>
        <footer_/>
    </div>
  </template>
  <script>
  import util from '../assets/js/util'
  import sha1 from 'js-sha1'
  import footer_ from '../components/footer.vue'
  import wx from '../assets/js/jweixin-1.6.0.js'
  export default {
    components: {
      footer_,
    },
    data() {
      return {
        search_value:'',
        state:3
      }
    },
    methods: {
        input_search(){
            if(this.search_value.length == 0){this.state = 3}
            
        },
        tu(path){
            this.$router.push(path)
        },
        async loadList(){
            if(this.search_value.length == 11   ){
                this.$api.article.isAchive(this.search_value).then(res=>{
                    console.log(res)
                this.state = res.data.result.result
            })
            }else{
                this.$toast('请输入正确的手机号')
            }
        },
        onClickLeft(){
            this.$router.go(-1)
        }

    },
    created() {
    },
    mounted(){
   

    }
  }
  </script>
  <style scopde lang="less">
    .getdrug{
        margin: auto 5px;
        height: calc(100vh - 200px);
        overflow-y: scroll;
    }
    .margin-auto{
        margin: auto 10px;
    }
    .van-error{
        width: 100%;
        height: 100%;
    }
    .margin-top{
        margin-top: 10px;
    }
    .van-cell__title{
        width: 65%;
    }
    .margin_right{
        margin-right: 10px;
    }
  </style>